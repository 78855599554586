<template>
    <inner-page-card heading="Add Bank" :link-back="true">
        <b-form class="row" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                :initial-data="initialData">

            <div class="col-12 col-lg-6">
                <validated-input label="Bank Name*" v-model="model.bank_name" name="Bank name"
                                 :rules="{required: true}" :disabled="loading"></validated-input>
            </div>

            <div class="col-12 col-lg-6">
                <validated-input label="Status" v-model="model.status"
                                 :rules="{required: true}" :disabled="true"></validated-input>
            </div>

            <div class="col-12 col-lg-12">
                <validated-file-input name="Bank logo" label="Bank logo*" v-model="model.bank_logo" :disabled="loading" class="c-file-input">
                    <template #append>
                        <div class="form-file-input-tag fl-x-cc text-white px-3">
                            <p class="m-0">Upload</p>
                            <img src="../../assets/web/dashboard/icons/arrow-up-16.png" alt="">
                        </div>
                    </template>
                </validated-file-input>
            </div>

            <div class="col-12">
                <div class="fl-x fl-j-c mt-4">
                    <div class="btn-group">

                        <btn text="Cancel" @click="$router.push({path: '/banks-list/'})" type="button"
                             class="px-3" design="basic-b" color="secondary"></btn>

                        <btn text="Add Bank" :loading="loading" loading-text="Adding..." class="px-3"
                             design="basic-a" color="secondary"></btn>
                    </div>
                </div>

            </div>
        </b-form>

        <modal class="c-modal no-close" :no-close-on-backdrop="false" title="" ref="SuccessModal" width="25r"
               header-color="">
            <div class="text-center">
                <div class="mb-4">
                    <img src="../../assets/web/homee/icons/success-tick.png" class="icon-img-md" alt="">
                </div>
                <h4 class="mb-4 font-inter-bold">Bank has been Added!</h4>
                <p class="font-inter-light mb-5">{{ addedItem && addedItem.bank_name }} has been added to your
                    Portal.</p>
                <btn text="Close" class="" type="button" @click="$router.push({path: '/banks-list/'})" block></btn>
            </div>
        </modal>

    </inner-page-card>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'AddBankPage',

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.setInitialData();
    },

    data () {
        return {
            addUrl : urls.auction.bank.addEdit,

            initialData : {
                bank_name : '',
                bank_logo : '',
                status    : 'Active'
            },

            addedItem : null
        };
    },

    methods : {
        setInitialData () {
            const today = new Date();
            const month = (today.getMonth() + 1) > 9 ? (today.getMonth() + 1) : '0' + (today.getMonth() + 1);
            const day = today.getDate() > 9 ? today.getDate() : '0' + today.getDate();
            const date = today.getFullYear() + '-' + month + '-' + day;

            this.initialData = {
                bank_name    : '',
                bank_logo    : '',
                status       : '',
                created_by   : this.currentUser.name,
                created_date : date
            };
            console.log('this.currentUser : ', this.initialData);
        },

        formSuccess (response) {
            console.log('response.data : ', response.data);
            this.addedItem = { ...response.data.data };
            this.$refs.SuccessModal.show();
        }
    }
};
</script>

<style scoped>

</style>
